import isNil from 'lodash/isNil';
import apiRequest from '../apiRequest';

export const find = async (propertyId, token) =>
    apiRequest(`properties/${propertyId}`, {
        method: 'GET',
        token
    });

export const paginate = async ({
    token,
    page,
    pageSize,
    q,
    sort_name,
    sort_direction,
    filters,
    multiBookingSameViewberProperty,
}) =>
    apiRequest('properties/paginate', {
        token,
        params: {
            page,
            page_size: pageSize,
            q: !isNil(q) ? q : undefined,
            sort_name,
            sort_direction,
            filters,
            multiBookingSameViewberProperty
        },
    });

export const create = async ({ token, ...rest }) =>
    apiRequest('properties', {
        method: 'POST',
        token,
        data: rest
    });

export const update = async ({id, token, ...rest}) =>
    apiRequest(`properties/${id}`, {
        method: 'PUT',
        token,
        data: rest
    });

export const destroy = async (propertyId, token) =>
    apiRequest(`properties/${propertyId}`, {
        method: 'DELETE',
        token
    });

export const propertyViewingPack = async (propertyId, token) =>
    apiRequest(`properties/${propertyId}/viewing-packs-available`, {
        method: 'GET',
        token
    });

export const propertyThirdPartyIntegrationsIdsSearch = (token, ids) =>
	apiRequest(`/properties/third-party-integrations/ids-search`, {
        method: 'POST',
        token,
        data: { ids }
    });

export const keyHolderUnavailability = ({ id, token, start, end, typeKeyholder }) =>
	apiRequest(`/properties/${id}/key-holder-calendar`, {
        method: 'GET',
        token,
        params: {
            start,
            end,
            keyholder: typeKeyholder
        }
    });